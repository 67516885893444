import React from "react";
import weblogo from "assets/images/MS-BLUE-LTD-logo-1.png";
import "components/Footer/footer.css";
import CILTApproved from "assets/images/CILT_Approved_Centre1.png";
import HIGHFIELD from "assets/images/HIGHFIELD-logo.png";
import blob from "assets/images/blob.png";

const Footer = () => {
  return (
    <div>
      <section className="section" style={{ padding: "40px 0px 30px 0px" }}>
        <div className="container">
          <div className="row">
            {/* <div className="col-md-2"></div> */}
            <div className="col-md-4 text-center">
              <div style={{ width: "100%", margin: "auto" }}>
                <img src={CILTApproved} />
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div style={{ width: "100%", margin: "auto" }}>
                <img src={HIGHFIELD} />
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div style={{ width: "100px", margin: "auto" }}>
                <a
                  href="https://www.credly.com/badges/6d15e3ea-2346-4eec-90bb-e49388a784d4/whatsapp"
                  // target="_blank"
                  // rel="noopener noreferrer"
                >
                  <img src={blob} alt="credly" />
                </a>
              </div>
            </div>
            {/* <div className="col-md-1"></div> */}
          </div>
        </div>
      </section>
      <div className="footer" style={{ padding: "30px 0px " }}>
        <footer className="footer" role="contentinfo">
          <div className="container MainFooter">
            <div className="row align-items-center">
              <div className="col-12 hero-text-image">
                <div className="row">
                  <div className="col-md-3 img-fluid">
                    <div style={{ height: "130px", width: "130px" }}>
                      <img className="img-fluid3" src={weblogo} />
                    </div>
                    <br />
                    <p>
                      With roots in Logistics and Manufacturing,
                      <br />
                      MS Blue aim to standardise and automate compliance and
                      fleet management and deliver most relevant Trainings
                      customised both for new as well as established businesses.
                    </p>
                  </div>
                  <div
                    className="col-md-3 img-fluid"
                    style={{ padding: "20px 10px" }}
                  >
                    <h3>USEFUL LINKS</h3>
                    <div className="map">
                      <ul>
                        <li>
                          <a
                            href="/"
                            style={{
                              fontSize: "22px !important;",
                              color: "#000",
                              fontWeight: "600",
                            }}
                          >
                            Home
                          </a>
                        </li>
                        <li>
                          <a
                            href="/consultancy"
                            style={{
                              fontSize: "22px !important;",
                              color: "#000",
                              fontWeight: "600",
                            }}
                          >
                            Consultancy
                          </a>
                        </li>
                        <li>
                          <a
                            href="/training"
                            style={{
                              fontSize: "22px !important;",
                              color: "#000",
                              fontWeight: "600",
                            }}
                          >
                            Training
                          </a>
                        </li>
                        <li>
                          <a
                            href="/contact-us"
                            style={{
                              fontSize: "22px !important;",
                              color: "#000",
                              fontWeight: "600",
                            }}
                          >
                            Contact Us
                          </a>
                        </li>
                        <li>
                          <a
                            href="/training-form"
                            style={{
                              fontSize: "22px !important;",
                              color: "#000",
                              fontWeight: "600",
                            }}
                          >
                            Registration Form
                          </a>
                        </li>
                      </ul>
                      <h5> Follow Us</h5>
                      <br />

                      <div>
                        <a
                          href="https://www.facebook.com/profile.php?id=100093977933296"
                          className="bi bi-facebook"
                        ></a>
                        <a
                          href="https://www.linkedin.com/company/msblue/"
                          className="bi bi-linkedin"
                        ></a>
                        <a
                          href="https://x.com/blue_ms49958"
                          className="bi bi-twitter-x"
                        ></a>
                        {/* <a href="#" className="bi bi-quora"></a> */}
                        <a
                          href="https://www.instagram.com/msblue.trainings/"
                          className="bi bi-instagram"
                        ></a>
                        <a
                          href="https://www.pinterest.co.uk/msbluetraining/"
                          className="bi bi-pinterest"
                        ></a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-3"
                    style={{ padding: "10px 10px 20px 10px" }}
                  >
                    <h3>CONTACT DETAILS</h3>
                    <div className="contactdetails">
                      <div className="col-mb-4 et_pb_blurb_content">
                        <i className="bi bi-geo-alt"></i>
                      </div>
                      <div className="col-mb-4">
                        <p>
                          C/O Unit B<br /> Greenhill House, Thorpe Road,
                          <br /> Peterborough,
                          <br /> Cambs,
                          <br /> United Kingdom,
                          <br /> PE3 6RU
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="contactdetails"
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      <div className="col-mb-4 et_pb_blurb_content">
                        <i className="bi bi-telephone"></i>
                      </div>
                      <div className="col-mb-4">
                        <h6>
                          {" "}
                          <a href="tel:01733 833211" style={{ color: "#000" }}>
                            01733 833211
                          </a>
                        </h6>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="contactdetails"
                      style={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      <div className="col-mb-4 et_pb_blurb_content">
                        <i className="bi bi-envelope"></i>
                      </div>
                      <div className="col-mb-4">
                        <h6>
                          {" "}
                          <a
                            href="mailto:info@msblue.uk"
                            style={{ color: "#000" }}
                          >
                            info@msblue.uk
                          </a>
                        </h6>
                      </div>
                    </div>
                    <hr />
                  </div>
                  <div className="col-md-3">
                    <h3>FIND US</h3>
                    <div className="map">
                      <p>
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d310368.17807633604!2d-0.300815!3d52.574107!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877f22a5bdd28d9%3A0x7ca43f11980e58cd!2sGREENHILL%20HOUSE%2C%20Thorpe%20Rd%2C%20Peterborough%20PE3%206RU%2C%20UK!5e0!3m2!1sen!2sus!4v1715174602814!5m2!1sen!2sus"
                          width={"100%"}
                          height={"280px"}
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="footer bottomBar">
        <div className="container">
          <div className="row justify-content">
            <div className="col-md-12">
              <p className="copyright">
                &copy; Copyrights MS Blue. © 2025 All Rights Reserved.{" "}
                <a
                  href="/appeals-policy"
                  role="button"
                  // style={{ color: "#fff", textDecoration: "auto" }}
                >
                  Appeals Policy
                </a>{" "}
                <a
                  href="/complaints-policy"
                  role="button"
                  // style={{ color: "#fff", textDecoration: "auto" }}
                >
                  Complaints Policy
                </a>
              </p>
              <div className="credits">
                {/* <p>
                  Designed and Developed by
                  <a href="https://www.techvezoto.com/">Tech Vezoto</a>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
